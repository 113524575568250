import React, {Component} from "react"
import style from "./HeaderWelcome.module.css"
import ActivityInfos from "../ActivityInfos";
import MascotteComponent from "../../Screen/ScreenComponents/MascotteComponent";

class HeaderWelcome extends Component {

    render() {
        return (
            <div className={style.header} style={{maxWidth: "100%"}}>

                <div className={style.activityInfosContainer}>
                    <ActivityInfos
                        name={this.props.name}
                        theme={this.props.theme}
                        themeColor={this.props.themeColor}
                        themeBaseColor={this.props.themeBaseColor}
                        secondTheme={this.props.secondTheme}
                        secondThemeColor={this.props.secondThemeColor}
                        secondThemeBaseColor={this.props.secondThemeBaseColor}
                        duration={this.props.duration}
                        ageMin={this.props.ageMin}
                    />
                </div>

                <div className={style.progressBarContainer}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", maxHeight: "100%", width: "100%", position: "relative"}}>

                        <div className={style.triangle}/> {/*style={{borderLeft: window.innerWidth*0.4 + "px solid transparent"}}/>*/}
                        <div className={style.mascotteContainer}>
                            <MascotteComponent
                                file={this.props.file}
                                dialogue={this.props.dialogue}
                                center={true}
                                orientation={this.props.orientation}
                                size={6}
                                color={"white"}
                                type={this.props.type}
                            />
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default HeaderWelcome;
