import React, {Component} from "react"
import style from "./QuizComponent.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircle, faCircleCheck, faCloud, faHeart, faSquare, faTimes} from "@fortawesome/free-solid-svg-icons";
import MascotteQuizComponent from "./MascotteQuizzComponent";
import {api_url} from "../../../Services/ConnectionService";
import Lottie from "lottie-react";
import success from "../../../assets/Sounds/Quizz_success.mp3"
import wrong from "../../../assets/Sounds/Quizz_wrong.mp3"
import star from "../../../assets/Etoile.svg";

class Quiz extends Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0,
            width: 0,
            heightMax : false,
            selected: undefined,
            isOpen: false,
            margin: undefined
        }
        this.isSelected = false
        this.isLoading = true;
        this.isLocked = false;
        this._answers = [];
        this._rightAnswer = undefined;
        this._lottieWA = undefined;
        this._lottieGA = undefined;
        this.isFirstXp = true;
        this.audioGA = new Audio(success);
        this.audioWA = new Audio(wrong);
        this.starsRender = [];
        this.starsVar = [];
        this.divRef = undefined;
        this.starRef = undefined;
        this.divTop = 0;
        this.divLeft = 0;
        this.divWidth = 0;
        this.divHeight = 0;
        this.starTop = 0;
        this.starLeft = 0;
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({
            height: height,
            width: width,
            heightMax: (width/height > 338/236),
            margin: (width/height > 338/236) ? (width - 338*0.95*height/236)/2 + 5 : 90
        });
    }

    calculateShowNext() {
        this.props.setShowNext(this.isSelected && !this.state.isOpen)
    }

    async nextTrigger() {
        if(this.state.isOpen) {
            return false
        }
        if(!this.isLocked) {
            if(this.isSelected) {
                this.isLocked = true
                if(this._rightAnswer === this.state.selected) {
                    this.props.setSuccess(this.props.nbStep, true)
                    this.audioGA.play()
                } else {
                    this.audioWA.play()
                }
                this.setState({isOpen: true})
                setTimeout(() => {this.calculateShowNext()}, 50)
            }
            return false
        } else {
            await new Promise(r => setTimeout(r, 200));
            return true
        }
    }

    select (answer) {
        if (!this.isLocked){
            this.setState({selected: answer})
            this.isSelected = true
            this.calculateShowNext()
        }
    }

    generateStarXp() {
        if(this.isFirstXp && !this.props.hasAlreadySucceeded && this._rightAnswer === this.state.selected && this.state.isOpen) {
            let res = [];
            for(let i=0; i<5; i++) {
                this.starsVar.push({
                    x: 0,
                    y: 0,
                    size: 0,
                    ref: null
                })
                res.push(
                    <div
                        ref={(node) =>{this.starsVar[i].ref = node}}
                        key={i.toString()}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: this.starsVar[i].size,
                            height: this.starsVar[i].size,
                            zIndex: 100000,
                            transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + "px)"
                        }}>
                        <img src={star} style={{width: '100%', height: '100%'}} alt={'a star :)'}/>
                    </div>
                )
            }
            setTimeout(this.animateXpGain.bind(this), 500)
            this.starsRender = res;
            return res
        }
        return this.starsRender
    }

    styleStringForStar(star) {
        const base = "align-items: center; justify-content: center; position: absolute; z-index: 100000; top: 0px; left: 0px; "
        const size = "width: " + this.starsVar[star].size.toString() + "px; height: " + this.starsVar[star].size.toString() + "px; "
        const transform = "transform: translate(" + this.starsVar[star].x.toString() + "px, " + this.starsVar[star].y.toString() + "px);"
        return base + size + transform
    }

    getRandom(min, max) {
        return Math.floor(min + (max-min)*Math.random())
    }

    animateXpGain() {
        this.divTop = this.divRef.getBoundingClientRect().top
        this.divLeft = this.divRef.getBoundingClientRect().left
        this.divWidth = this.divRef.clientWidth
        this.divHeight = this.divRef.clientHeight
        this.starTop = this.starRef.getBoundingClientRect().top
        this.starLeft = this.starRef.getBoundingClientRect().left
        for(let i in this.starsVar) {
            let newX = this.starLeft - this.divLeft + this.getRandom(-60, 60)
            let newY = this.starTop - this.divTop + this.getRandom(-60, 60)
            this.starsVar[i].x = newX;
            this.starsVar[i].y = newY;
            setTimeout(() => {
                this.starsVar[i].ref.style = this.styleStringForStar(i)
            }, 5)
            setTimeout(() => {
                this.starsVar[i].ref.animate([
                    {width: "0px", height: "0px"},
                    {width: "50px", height: "50px"},
                ], {
                    duration: 200,
                    easing: 'linear'
                })
            }, 50)
            setTimeout(() => {
                this.starsVar[i].size = 50
            }, 150)
            setTimeout(() => {
                this.starsVar[i].ref.style = this.styleStringForStar(i)
            }, 155)
            setTimeout(() => {
                this.starsVar[i].ref.animate([
                    {transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + "px)"},
                    {transform: "translate(" + (this.starsVar[i].x + (3*(this.divWidth - this.starsVar[i].x))/4).toString() + "px, " + (this.starsVar[i].y + (3*(0 - this.starsVar[i].y))/4).toString() + "px)"},
                ], {
                    duration: 605,
                    easing: 'ease-in'
                })
            }, 600)
            setTimeout( () => {
                this.starsVar[i].ref.animate([{
                    transform: "translate(" + (this.starsVar[i].x + (3*(this.divWidth - this.starsVar[i].x))/4).toString() + "px, " + (this.starsVar[i].y + (3*(0 - this.starsVar[i].y))/4).toString() + "px)",
                    width: this.starsVar[i].size.toString() + "px",
                    height: this.starsVar[i].size.toString() + "px",
                }, {
                    transform: "translate(" + this.divWidth.toString() + "px, 0px)",
                    width: 0,
                    height: 0
                },
            ], {
                duration: 150,
                easing: 'ease-in'
            })}, 1200)
            setTimeout(() => {
                this.starsVar[i].x = this.divWidth
                this.starsVar[i].y = 0
                this.starsVar[i].size = 0
                this.starsVar[i].ref.style = this.styleStringForStar(i)
            }, 1300)
        }
        setTimeout(() => {this.props.addXp(this.props.bonusXp); this.props.expandStar()}, 1300)
    }

    shuffleAnswer() {
        const list = [1,2,3,4];
        list.sort(() => Math.random() - 0.5);
        for(const item in list){
            if(list[item] === 1){
                this._answers.push(this.props.goodAnswer);
                this._rightAnswer = parseInt(item) + 1;
                this.forceUpdate();
            } else if  (list[item] === 2) {
                this._answers.push(this.props.wrongAnswer1);
                this.forceUpdate()
            } else if  (list[item] === 3) {
                this._answers.push(this.props.wrongAnswer2);
                this.forceUpdate()
            } else if  (list[item] === 4) {
                this._answers.push(this.props.wrongAnswer3);
                this.forceUpdate()
            }

            this.isLoading = false;
            this.forceUpdate();
        }
    }

    getLottieWA() {
        if(this._lottieWA === undefined){
            let request = new Request(api_url + "/uploads/mascotte/" + this.props.fileWA, {method:'POST'});

            fetch(request).then((response) =>response.json())
                .then(data => {
                    this._lottieWA = data;
                    this.forceUpdate();
                })
        }
    }

    getLottieGA() {
        if(this._lottieGA === undefined){
            let request = new Request(api_url + "/uploads/mascotte/" + this.props.fileGA, {method:'POST'});

            fetch(request).then((response) =>response.json())
                .then(data => {
                    this._lottieGA = data;
                    this.forceUpdate();
                })
        }
    }

    getMascotte(){
        if(this._rightAnswer === this.state.selected){
            if(this.props.typeGA === "lottie"){
                if(this._lottieGA){
                    return(
                        <Lottie
                            animationData={this._lottieGA}
                            loop={true}
                            style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}/>
                    )
                } else {
                    this.getLottieGA()
                }
            } else if (this.props.fileGA) {
                return (
                    <img
                        src={api_url + '/uploads/mascotte/' + this.props.fileGA}
                        alt={"Mascotte"}
                        style={{
                            height: "100%",
                    }}/>
                )
            }
        } else {
            if(this.props.typeWA === "lottie"){
                if(this._lottieWA){
                    return(
                        <Lottie
                            animationData={this._lottieWA}
                            loop={true}
                            style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}/>
                    )
                } else {
                    this.getLottieWA()
                }
            } else if (this.props.fileWA) {
                return (
                    <img
                        src={api_url + '/uploads/mascotte/' + this.props.fileWA}
                        alt={"Mascotte"}
                        style={{
                            height: "100%",
                        }}/>
                )
            }
        }

    }

    getIconGA() {
        switch (this._rightAnswer){
            case 1:
                return faSquare;
            case 2:
                return faCircle;
            case 3:
                return faHeart;
            case 4:
                return faCloud;
            default:
                return null;
        }
    }

    render() {
        if(this.isLoading){
            this.shuffleAnswer();
        }

        return (
            <div style={{flex: 1, display: "flex", flexDirection: "column"}} ref={(node) => {this.divRef = node}}>
                {/*  Mascotte  */}
                <div style={{flex: 4, display: "flex", height: "40%"}}>
                    {this.state.margin &&
                        <MascotteQuizComponent
                            orientation={this.props.orientationQ}
                            dialogue={this.props.question}
                            file={this.props.fileQ}
                            margin={this.state.margin}
                        />
                    }

                </div>

                <div
                    style={{flex: 6, display: "flex", alignItems: "center", justifyContent: "center", maxHeight: "60%"}}
                    ref={ (divElement) => { this.divElement = divElement } }
                >
                    <div style={{
                        aspectRatio : 338/236,
                        maxHeight: "100%",
                        maxWidth: "100%",
                        height: this.state.heightMax ? "95%" : undefined,
                        width: this.state.heightMax ? undefined : "95%",
                        display: "flex",
                        flexDirection: "column",
                        gap: this.state.height*0.02
                    }}>
                        <div className={style.answerLine}>
                            <button style={{width: '52.5%'}} className={this.state.selected === 1 ? style.selectedLeftAnswer : this.isLocked ? style.lockedLeftAnswer : style.leftAnswer} onClick={() => this.select(1)}>
                                <img src={this.state.selected && this.state.selected !== 1 ? require("../../../assets/Quiz/Rect_grisrouge.png") : require("../../../assets/Quiz/Rect_rouge.png")} className={style.leftImg}/>
                                <div className={style.answerTextContainer} style={{width: "80%", left: 0}}>
                                    <FontAwesomeIcon icon={this.isLocked && this._rightAnswer === 1 ? faCheck : this.isLocked ? faTimes : faSquare} style={{left: 0}} className={style.icon}/>
                                    <p className={style.answerText}>
                                        {this._answers[0]}
                                    </p>
                                </div>
                            </button>

                            <button style={{width: '52.5%'}} className={this.state.selected === 2 ? style.selectedRightAnswer : this.isLocked ? style.lockedRightAnswer : style.rightAnswer} onClick={() => this.select(2)}>
                                <img src={this.state.selected && this.state.selected !== 2 ? require("../../../assets/Quiz/Rect_grisjaune.png") : require("../../../assets/Quiz/Rect_jaune.png")} className={style.rightImg}/>
                                <div className={style.answerTextContainer} style={{width: "80%", right: 0}}>
                                    <FontAwesomeIcon icon={this.isLocked && this._rightAnswer === 2 ? faCheck : this.isLocked ? faTimes : faCircle} style={{right: 0}} className={style.icon}/>
                                    <p className={style.answerText}>
                                        {this._answers[1]}
                                    </p>
                                </div>
                            </button>
                        </div>

                        <div className={style.answerLine}>
                            <button style={{width: '52.5%'}}  className={this.state.selected === 3 ? style.selectedLeftAnswer : this.isLocked ? style.lockedLeftAnswer : style.leftAnswer} onClick={() => this.select(3)}>
                                <img src={this.state.selected && this.state.selected !== 3 ? require("../../../assets/Quiz/Rect_grisvert.png") : require("../../../assets/Quiz/Rect_vert.png")} className={style.leftImg}/>
                                <div className={style.answerTextContainer} style={{width: "80%", left: 0}}>
                                    <FontAwesomeIcon icon={this.isLocked && this._rightAnswer === 3 ? faCheck : this.isLocked ? faTimes : faHeart} style={{left: 0}} className={style.icon}/>
                                    <p className={style.answerText}>
                                        {this._answers[2]}
                                    </p>
                                </div>
                            </button>

                            <button style={{width: '53.8%'}}  className={this.state.selected === 4 ? style.selectedRightAnswer : this.isLocked ? style.lockedRightAnswer : style.rightAnswer} onClick={() => this.select(4)}>
                                <img src={this.state.selected && this.state.selected !== 4 ? require("../../../assets/Quiz/Rect_grisviolet.png") : require("../../../assets/Quiz/Rect_violet.png")} className={style.rightImg}/>
                                <div className={style.answerTextContainer} style={{width: "80%", right: 0}}>
                                    <FontAwesomeIcon icon={this.isLocked && this._rightAnswer === 4 ? faCheck : this.isLocked ? faTimes : faCloud} style={{right: 0}} className={style.icon}/>
                                    <p className={style.answerText}>
                                        {this._answers[3]}
                                    </p>
                                </div>
                            </button>
                        </div>
                    </div>

                </div>

                {this.state.isOpen &&
                    <div
                        style={{
                            zIndex: 90000,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%", width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        {!this.props.hasAlreadySucceeded && this.state.isOpen && (this._rightAnswer === this.state.selected) && this.generateStarXp()}
                        <div
                            style={{
                                position: "absolute",
                                top: 0, left: 0,
                                width: "100%",
                                height: "40%",
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "10px"
                        }}>
                            {this.getMascotte()}
                        </div>


                        <div
                            style={{
                                width:  window.innerWidth > 1500 ? "40%" : window.innerWidth > 1200 ? "50%" : "60%",
                                padding: window.innerWidth > 1300 ? "40px" : "20px",
                                top: "10%",
                                position: "relative"
                            }}

                            className={this._rightAnswer === this.state.selected ? style.goodModal : style.badModal}
                        >
                            {this._rightAnswer === this.state.selected ?
                                <span className={style.title} style={{color: '#8AC53E'}}>Bravo</span>:
                                <span className={style.title} style={{color: '#CE3534'}}>Dommage</span>
                            }

                            {this._rightAnswer === this.state.selected ?
                                !this.props.hasAlreadySucceeded &&
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: "center"}}>
                                    <span className={style.subtitle}>Tu gagnes {this.props.bonusXp} </span>
                                    <div
                                        ref={(node) => {this.starRef = node}}
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 30,
                                            height: 30,
                                            zIndex: 1000,
                                        }}>
                                        <img src={star} style={{width: '100%', height: '100%'}} alt={'a star :)'}/>
                                    </div>
                                </div>
                                :
                                <span className={style.subtitle}>
                                    La bonne réponse est : <FontAwesomeIcon icon={this.getIconGA()} style={{marginLeft: "5px"}}/>
                                    <br/>
                                    {this.props.goodAnswer}
                                </span>
                            }

                            {this._rightAnswer === this.state.selected ?
                                <p className={style.text}>{this.props.explanationGA}</p> :
                                <p className={style.text}>{this.props.explanationBA}</p>
                            }

                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                onClick={() => {
                                    this.setState({isOpen: false})
                                    setTimeout(() => {this.props.actionNext()}, 50)
                                }}
                                className={style.iconBtn}
                                style={{color: this._rightAnswer === this.state.selected ? '#8AC53E' : '#CE3534'}}
                            />
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default Quiz;
