import React, {Component} from "react"
import style from "./Footer.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronRight, faSignOut, faStar, faTimes} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import star from "../../assets/Etoile.svg";
import collect_star from "../../assets/Sounds/Collect_star.mp3";
import {CircularProgressbar} from "react-circular-progressbar";


class Footer extends Component {

    constructor(props) {
        super(props);
        this.currentSound = 0;
        this.lastXp = this.props.xp
        this.xp = this.props.xp
        this.showNext = this.props.showNext
        this.starAvailable = true;
        this.starDivRef = null;
        this.xpUpdate = false;
        this.isXpChanging = false;
        this.timerMax = 0;
        this.timerTime = 0;
        this.isTimer = false;
        this.isPlaying = false
        this.state = {
            color: "red"
        }
    }

    tick() {
        if(this.isTimer){
            if(this.state.color === "red"){
                this.setState({color: "darkgray"});
            } else {
                this.setState({color: "red"});
            }
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }



    addXp(xp) {
        if(!this.isPlaying) {
            this.isPlaying = true
            let sound = new Audio(collect_star)
            sound.volume = 1;
            sound.play()
            setTimeout(() => {this.isPlaying = false}, 100)
        }
        this.xp = this.xp + xp
        this.xpUpdate = true
        if(!this.isXpChanging) {
            this.lastXp = this.xp
        }
        this.isXpChanging = true
        setTimeout(()=>{this.isXpChanging = false}, 105)
        this.forceUpdate()
    }

    expandStar() {
        if(this.starAvailable) {
            this.starAvailable = false
            this.starDivRef.animate([
                {width: "55px", height: "55px"},
                {width: "85px", height: "85px"},
                {width: "55px", height: "55px"},
            ], {
                duration: 500,
                easing: 'linear'
            })
            setTimeout(() => {this.starAvailable = true}, 500)
        }
    }

    setShowNext(bool) {
        this.lastXp = this.xp
        this.showNext = bool
        this.forceUpdate()
    }

    addTimer = (bool) => {
        this.lastXp = this.xp
        this.isTimer = bool;
        this.forceUpdate();
    }

    setRemainingTime = (rT, dur) => {
        this.lastXp = this.xp
        this.timerTime = rT;
        this.timerMax = dur;
        this.forceUpdate();
    }

    convert = (col1, col2, p) => {
        const rgb1 = parseInt(col1, 16);
        const rgb2 = parseInt(col2, 16);
        const [r1, g1, b1] = this.toArray(rgb1);
        const [r2, g2, b2] = this.toArray(rgb2);
        const q = 1-p;
        const rr = Math.round(r1 * p + r2 * q);
        const rg = Math.round(g1 * p + g2 * q);
        const rb = Math.round(b1 * p + b2 * q);
        return  Number((rr << 16) + (rg << 8) + rb).toString(16);
    }

    interpolate = (percent) => {
        return "#" + (percent > 50 ? this.convert("A30000", "FFBA08", (percent - 50)/50) : this.convert("FFBA08", "8AC53E", percent/50))
    }

    toArray(rgb) {
        const r = rgb >> 16;
        const g = (rgb >> 8) % 256;
        const b = rgb % 256;

        return [r, g, b];
    }

    children = (remainingTime) => {
        if(remainingTime === 0) {
            return "!"
        }
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60
        if(minutes < 1){
            return seconds.toString()
        }
        if(seconds < 10){
            return minutes.toString() + ":0" + seconds.toString()
        }
        return minutes.toString() + ":" + seconds.toString()
    }


    render() {

        return (
            <div className={style.footer}>

                <div style={{flex: 6, paddingBottom: "30px"}}>
                    {!this.props.hideStars &&
                        <div className={style.starContainer}>
                            <div style={{width: 110, height: 80, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <div
                                    style={{width: 55, height: 55}}
                                    ref={(node) => {this.starDivRef = node}}
                                    className={style.starDiv}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            height: this.props.totalXp && this.props.totalXp > 0 && this.props.totalXp > this.xp ? 100 - 100*this.xp/this.props.totalXp + "%" : 0}}
                                        ref={(node) => {this.starDivRef = node}}
                                        className={style.starGray}
                                    />

                                    {this.props.totalXp <= this.xp &&
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: 0,
                                                right: 0,
                                                background: "#6FA939",
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "20px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontSize: "1.2rem"
                                            }}>
                                            <FontAwesomeIcon icon={faCheck} color={"white"}/>
                                        </div>
                                    }

                                </div>
                            </div>
                            <CountUp
                                start={this.lastXp}
                                end={this.xp}
                                duration={0.1}
                                delay={0}
                            />
                        </div>
                    }
                </div>

                <div className={style.nextContainer}>
                    {this.showNext &&
                        <button onClick={this.props.actionNext} className={style.chevron}>
                            <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "40px"}} color={"#6A6A6B"}/>
                        </button>
                    }
                </div>

                <div className={style.timerContainer}>
                    {this.isTimer &&
                        <div style={{width: "50%"}} className={this.timerTime === 0 ? style.clignote : undefined}>
                            <CircularProgressbar
                                minValue={0}
                                maxValue={this.timerMax}
                                value={this.timerTime}
                                text={this.children(this.timerTime)}
                                counterClockwise={true}
                                styles={{
                                    path: {
                                        stroke: this.interpolate(Math.floor((1 - (this.timerTime/this.timerMax)) * 100)),
                                        strokeLinecap: 'round',
                                    },
                                    trail: {
                                        stroke: this.timerTime === 0 ? this.state.color : "#CCC",
                                    },
                                    text: {
                                        fill: this.timerTime === 0 ? this.state.color : 'black',
                                        fontSize: window.innerWidth < 1500 ? '1.8vmax' : '1.3vmax',
                                        dominantBaseline: 'middle',
                                        textAnchor: 'middle'
                                    }
                                }}
                            />
                        </div>
                    }
                </div>

                <div className={style.timesContainer}>
                    {!this.props.hideLeave &&
                        <button className={style.timesBtn} onClick={this.props.closeTab}>
                            <FontAwesomeIcon icon={faSignOut} color={"#6A6A6B"} style={{fontSize: "2vmax"}}/>
                        </button>
                    }

                </div>

            </div>
        )
    }
}

export default Footer;
